'use client';
import Image from 'next/image';
import React from 'react';

interface ImageHandlerProps {
  src: string;
  alt: string;
  width: number;
  height: number;
}

const ImageHandler: React.FC<ImageHandlerProps> = ({
  src,
  alt,
  width,
  height,
}) => {
  // Helper function to fix invalid or protocol-relative URLs
  const sanitizeUrl = (url: string): string => {
    if (url.startsWith('//')) {
      return `https:${url}`; // Convert protocol-relative URL to https://
    }
    if (url.startsWith('https:/') && !url.startsWith('https://')) {
      return url.replace('https:/', 'https://'); // Fix malformed https URL
    }
    return url; // Return valid URL as is
  };

  // Sanitize the src before passing it to the Image component
  const sanitizedSrc = sanitizeUrl(src);

  return (
    <Image
      src={sanitizedSrc}
      width={width}
      height={height}
      className='object-cover max-lg:h-32'
      alt={alt}
      onError={(e) => {
        e.currentTarget.classList.add('hidden');
        e.currentTarget.src = ''; // Clear the src to avoid error loops
      }}
    />
  );
};

export default ImageHandler;
